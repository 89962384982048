<template>
  <div>
    <!-- FILTROS -->
    <b-card>
      <h2>Filtros</h2>
      <validation-observer ref="formPesquisar">
        <b-form class="mt-2">
          <b-row>
            <b-col cols="2">
              <b-form-group label="Ano de Competência" label-for="anoDeCompetencia">
                <validation-provider #default="{ errors }" name="Ano de Competência" rules="required">
                  <v-select-pt
                    id="anoDeCompetencia"
                    name="anoDeCompetencia"
                    v-model="filtroCapital.ano"
                    :options="anoOpcoes"
                    :reduce="(option) => option.value"
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="2">
              <b-form-group label="Mês de Competência" label-for="mesDeCompetencia">
                <validation-provider #default="{ errors }" name="Mês de Competência" rules="required">
                  <v-select-pt
                    id="mesDeCompetencia"
                    name="mesDeCompetencia"
                    v-model="filtroCapital.mes"
                    :options="mesOpcoes"
                    :reduce="(option) => option.value"
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Botões -->
          <b-row align-h="end">
            <b-col md="auto">
              <b-button
                :disabled="isBusy"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="margin-top: 21px; width: 160px"
                @click="carregarGrid()"
              >
                {{ !isBusy ? 'Prévia' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>
            <b-col md="auto">
              <template>
                <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                  <template #button-content>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      style="margin-top: 21px; width: 160px"
                    >
                      Exportar
                    </b-button>
                  </template>
                  <b-dropdown-item href="#" @click="exportToPDF()">
                    <feather-icon icon="FileTextIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para PDF">PDF</span>
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click="exportToExcel()">
                    <feather-icon icon="CheckCircleIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para excel">Excel</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- TABELA -->
    <b-card v-if="this.itemsTabela.length > 0 || isBusy">
      <template v-if="isBusy">
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando Tabela...</strong>
        </div>
      </template>
      <!-- TOTALIZADORES -->
      <div v-if="!isBusy" v-show="this.itemsTabela.length > 0">
        <b-row>
          <b-col md="12">
            <!-- GRID -->
            <div ref="tabelaGrid" :itemsTabela="this.itemsTabela">
              <b-table
                style="overflow-x: auto; -webkit-overflow-scrolling: touch"
                outlined
                id="tabelaGrid"
                ref="tabelaGrid"
                striped
                :per-page="perPage"
                :current-page="currentPage"
                :fields="this.currentFields"
                :items="this.itemsTabela"
                responsive="sm"
                class="position-relative custom-rounded-table"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
              >
              </b-table>
              <b-card-body v-if="this.itemsTabela.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
                <!-- page length -->
                <b-form-group
                  style="align-items: center"
                  label="Por Página"
                  label-cols="6"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
                </b-form-group>

                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="this.itemsTabela.length"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-card-body>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <relatorioFinanceiroCapitalAplicadoXRentabilidadeRetrato ref="exportarPDF" :parametro="parametroPDF" />
      </div>
    </b-card>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, dataFutura, dataPassada, valor } from '@validations'
  import {
    formatarData,
    formatarDataGlobal,
    formatarValorReais,
    formatarParaNumber,
    formatarValorPorcentagem,
    formatarCompetenciaDescricao,
  } from '@/libs/utils'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import relatorioFinanceiroCapitalAplicadoXRentabilidadeRetrato from './components/relatorioFinanceiroCapitalAplicadoXRentabilidadeRetrato.vue'
  import { VueSelect } from 'vue-select'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import VueHtml2pdf from 'vue-html2pdf'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import * as XLSX from 'xlsx'

  export default {
    components: {
      valor,
      VueHtml2pdf,
      ToastificationContent,
      formatarData,
      formatarValorPorcentagem,
      formatarValorReais,
      generateAndDownloadPdf,
      formatarValorReais,
      formatarCompetenciaDescricao,
      relatorioFinanceiroCapitalAplicadoXRentabilidadeRetrato,
      useJwt,
      dataPassada,
      formatarData,
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      VueSelect,
      required,
      VueSelect,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        itemsTabela: [],
        valor: 0,
        isBusy: false,
        totalRows: 1,
        nomeRelatorio: 'Relatório de Capital Aplicado x Rentabilidade',
        competencia: '',
        pesquisarPor: '',
        parametroPDF: {
          dados: [],
          colunas: [],
        },
        linhasTabela: [],
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filtroCapital: {
          ano: '',
          mes: '',
        },
        anoOpcoes: [
          { value: 2021, text: '2021' },
          { value: 2022, text: '2022' },
          { value: 2023, text: '2023' },
          { value: 2024, text: '2024' },
          { value: 2025, text: '2025' },
          { value: 2026, text: '2026' },
          { value: 2027, text: '2027' },
          { value: 2028, text: '2028' },
          { value: 2029, text: '2029' },
          { value: 2030, text: '2030' },
        ],
        mesOpcoes: [
          { value: 1, text: 'Janeiro' },
          { value: 2, text: 'Fevereiro' },
          { value: 3, text: 'Março' },
          { value: 4, text: 'Abril' },
          { value: 5, text: 'Maio' },
          { value: 6, text: 'Junho' },
          { value: 7, text: 'Julho' },
          { value: 8, text: 'Agosto' },
          { value: 9, text: 'Setembro' },
          { value: 10, text: 'Outubro' },
          { value: 11, text: 'Novembro' },
          { value: 12, text: 'Dezembro' },
        ],
        number: {},
        colunasTabelas: [],
        currentFields: [
          {
            key: 'competencia',
            label: 'Competência',
            sortable: true,
          },
          {
            key: 'capitalAplicado',
            label: 'Capital Aplicado',
            sortable: true,
          },
          {
            key: 'valorEmprestado',
            label: 'Valor Emprestado',
            sortable: true,
            formatter: (value) => formatarValorReais(value),
          },
          {
            key: 'amortizacao',
            label: 'Amortização',
            sortable: true,
            formatter: (value) => formatarValorReais(value),
          },
          {
            key: 'rentabilidade',
            label: 'Rentabilidade',
            sortable: true,
            formatter: (value) => formatarValorReais(value),
          },

          {
            key: 'percentual',
            label: 'Percentual',
            sortable: true,
          },
        ],
      }
    },
    mounted() {
      if (this.userData.role !== 'master') {
        this.institutoId = this.userData.institutoId
      } else {
        this.institutoId = this.userData.institutoSelecionado
      }
    },
    methods: {
      carregarGrid() {
        this.isBusy = true
        const mes = this.filtroCapital.mes < 10 ? `0${this.filtroCapital.mes}` : this.filtroCapital.mes
        this.competencia = `${this.filtroCapital.ano}${mes}`

        let filtro = {
          institutoId: this.institutoId,
          competencia: formatarParaNumber(this.competencia) ?? undefined,
        }

        useJwt
          .post('financeiro/getRelatorioCapitalAplicadoXRentabilidade', filtro)
          .then((response) => {
            if (!response.data.length > 0) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Alerta!',
                    text: 'Não há dados para o filtro inserido!',
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
              this.itemsTabela = []

              return
            }
            this.itemsTabela = response.data.map((item) => ({
              ...item,
              capitalAplicado: item.capitalAplicado,
              valorEmprestado: item.montanteUtilizado,
              amortizacao: item.amortizacaoNoMes,
              // amortizacaoAcumulada: formatarValorReais(item.amortizacaoAcumulada),
              rentabilidade: item.rentabilidade,
              // rentabilidadeAcumulada: (item.rentabilidadeAcumulada),
              percentual: formatarValorPorcentagem(item.percentualRentabilidadeAoCapital),
              competencia: formatarCompetenciaDescricao(item.competencia),
            }))

            this.totalRows = this.itemsTabela.length

            this.items = response.data
            this.linhasTabela = this.itemsTabela
            ;(this.colunasTabelas = this.currentFields.map((item) => ({
              label: item.label,
              key: item.key,
            }))),
              // Monta variável global de relatório PDF
              (this.parametroPDF = {
                dados: this.linhasTabela,
                colunas: this.colunasTabelas,
                institutoNome: this.userData.Instituto.nome,
              })
          })
          .catch((error) => {
            console.error(error)
            this.isBusy = false
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      exportToPDF() {
        if (!this.itemsTabela.length > 0) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }
        if (this.$refs.tabelaGrid) {
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2Pdfportrait.$el.innerHTML
          let relatorioPaisagem = true
          let nomeInstituto = this.userData.Instituto.nome
          let periodoFiltro = `Período: ${this.dataInicial} a ${this.dataFinal}`
          let nomeRelatorio = this.nomeRelatorio
          let informacoesInstituto = `
                  ${this.userData.Instituto.logradouro},
                  ${this.userData.Instituto.numero},
                  ${this.userData.Instituto.bairro},
                  ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                  E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            periodoFiltro,
            nomeRelatorio,
            informacoesInstituto,
            relatorioPaisagem,
            this.userData.Instituto.id,
          )
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
      exportToExcel() {
        if (!this.itemsTabela.length) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }

        // Usando os dados e colunas para criar a planilha
        const ws = XLSX.utils.json_to_sheet(this.itemsTabela)

        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Relatorio')

        // Salvando o arquivo
        XLSX.writeFile(wb, 'Relatorio.xlsx')
      },
    },
  }
</script>
