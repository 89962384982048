<template>
  <div>
    <b-card>
      <h2>Filtros</h2>
      <validation-observer ref="formPesquisar">
        <b-form class="mt-2">
          <b-row>
            <b-col cols="2">
              <b-form-group label="Competência Inicial" label-for="competenciaInicial">
                <validation-provider #default="{ errors }" name="Competência Inicial" rules="required">
                  <date-picker
                    v-model="filtro.competenciaInicial"
                    format="MM/YYYY"
                    class="w-100 h-100 input-group-merge form-control-merge"
                    type="month"
                    value-type="date"
                    lang="pt-br"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-form-group label="Competência Final" label-for="competenciaFinal">
                <validation-provider #default="{ errors }" name="Competência Final" rules="required">
                  <date-picker
                    v-model="filtro.competenciaFinal"
                    format="MM/YYYY"
                    class="w-100 h-100 input-group-merge form-control-merge"
                    type="month"
                    value-type="date"
                    lang="pt-br"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group label="Órgão:" label-for="pesquisarOrgao">
                <v-select-pt
                  id="pesquisarOrgao"
                  :clearable="false"
                  v-model="pesquisarOrgao"
                  :options="tipoOrgao"
                  label="nome"
                  class="tw-w-full tw-max-w-md tw-mb-4 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-sm:text-sm"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-row>
                <b-col md="auto">
                  <b-button
                    :disabled="isBusy"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    style="margin-top: 21px; width: 160px"
                    @click="carregarGrid()"
                  >
                    {{ !isBusy ? 'Prévia' : '' }}
                    <b-spinner small v-if="isBusy" />
                  </b-button>
                </b-col>
                <b-col md="auto">
                  <template>
                    <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                      <template #button-content>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          style="margin-top: 21px; width: 160px"
                        >
                          Exportar
                        </b-button>
                      </template>
                      <b-dropdown-item href="#" @click="exportToPDF()">
                        <feather-icon icon="FileTextIcon" size="16" />
                        <span class="align-middle ml-50" title="Exportar para PDF">PDF</span>
                      </b-dropdown-item>
                      <b-dropdown-item href="#" @click="exportToExcel()">
                        <feather-icon icon="CheckCircleIcon" size="16" />
                        <span class="align-middle ml-50" title="Exportar para excel">Excel</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <!-- TABELA -->

    <b-card v-if="this.itemsTabela.length > 0 || isBusy">
      <template v-if="isBusy">
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando Tabela...</strong>
        </div>
      </template>
      <div v-if="!isBusy" v-show="this.itemsTabela.length > 0">
        <b-row>
          <b-col md="2">
            <p>
              Total de Órgãos: <b-badge variant="primary"> {{ totalRows }}</b-badge>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2">
            <p>
              Valor Total: <b-badge variant="primary"> {{ valorTotalAcumulado }}</b-badge>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <!-- GRID -->
            <div ref="tabelaGrid" :itemsTabela="this.itemsTabela">
              <b-table
                style="overflow-x: auto; -webkit-overflow-scrolling: touch"
                outlined
                id="tabelaGrid"
                ref="tabelaGrid"
                striped
                :per-page="perPage"
                :current-page="currentPage"
                :fields="this.currentFields"
                :items="this.itemsTabela"
                responsive="sm"
                class="position-relative custom-rounded-table"
                :row-details="(row) => row.item.detailsShown"
              >
              </b-table>
              <b-card-body v-if="this.itemsTabela.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
                <!-- page length -->
                <b-form-group
                  style="align-items: center"
                  label="Por Página"
                  label-cols="6"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
                </b-form-group>

                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="this.itemsTabela.length"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-card-body>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <relatorioFinaceiroContabilPaisagem ref="exportarPDF" :parametro="parametroPDF" id="exportarPDF" />
      </div>
    </b-card>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, dataFutura, dataPassada, valor } from '@validations'
  import { formatarData, formatarDataGlobal, formatarValorReais, formatarParaNumber } from '@/libs/utils'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import relatorioFinaceiroContabilPaisagem from './components/relatorioFinaceiroContabilPaisagem.vue'
  import { VueSelect } from 'vue-select'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import VueHtml2pdf from 'vue-html2pdf'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import * as XLSX from 'xlsx'

  export default {
    components: {
      valor,
      VueHtml2pdf,
      ToastificationContent,
      formatarData,
      formatarValorReais,
      generateAndDownloadPdf,
      relatorioFinaceiroContabilPaisagem,
      useJwt,
      dataPassada,
      formatarData,
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      VueSelect,
      required,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        tipoOrgao: [{ value: undefined, nome: 'TODOS' }],
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        itemsTabela: [],
        isBusy: false,
        totalRows: 1,
        nomeRelatorio: 'Relatório Contábil',
        pesquisarOrgao: '',
        parametroPDF: {
          dados: [],
          colunas: [],
          colunas2: [],
          dadosTotal: [],
        },
        linhasTabela: [],
        valorTotalAcumulado: 0,
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        number: {},
        colunasTabelas: [],
        currentFields: [
          {
            key: 'orgao',
            label: 'Órgão',
            sortable: true,
          },
          {
            key: 'emprestimosConcedidos',
            label: 'Empréstimos Concedidos',
            sortable: true,
          },
          {
            key: 'valorPago',
            label: 'Valor Pago',
            sortable: true,
          },
        ],
        filtro: {
          competenciaInicial: '',
          competenciaFinal: '',
        },
      }
    },
    mounted() {
      this.carregaOrgaos()
    },
    methods: {
      carregarGrid() {
        if (!this.filtro.competenciaInicial || !this.filtro.competenciaFinal) {
          this.$message.warning('Por favor, informe a competência inicial e final!')

          this.itemsTabela.length = 0
          return
        }
        this.isBusy = true
        let filtro = {
          pesquisarOrgao: this.pesquisarOrgao,
          institutoId: this.userData.institutoSelecionado,
          competenciaInicial: this.filtro.competenciaInicial,
          competenciaFinal: this.filtro.competenciaFinal,
        }

        useJwt
          .post('financeiro/GetRelatorioContabil', filtro)
          .then((response) => {
            if (!response.data.length > 0) {
              this.$message.error('Não há dados para exportar!')
              this.itemsTabela.length = 0
              return
            }
            this.itemsTabela = response.data.map((item) => ({
              ...item,
              orgao: item.orgao,
              emprestimosConcedidos: item.emprestimosConcedidos,
              valorPago: formatarValorReais(item.valorPago),
            }))

            this.itemsTabela = this.itemsTabela.sort((itemA, itemB) => itemA.orgao.localeCompare(itemB.orgao))

            this.valorTotalAcumulado = formatarValorReais(response.data[0].valorTotal)
            this.totalRows = this.itemsTabela.length
            this.items = response.data
            this.linhasTabela = this.itemsTabela
            this.colunasTabelas = this.currentFields.map((item) => ({
              label: item.label,
              key: item.key,
            }))

            const colunasPDF = [
              {
                key: 'orgao',
                label: 'Órgão',
              },
              {
                key: 'emprestimosConcedidos',
                label: 'Empréstimos Concedidos',
              },
              {
                key: 'valorPago',
                label: 'Valor Pago',
              },
            ]
            const colunasPDF2 = [
              {
                key: 'valorTotalAcumulado',
                label: '',
              },
            ]
            const itemsPDF = this.linhasTabela.map((item) => {
              return {
                ...item,
                ...item.detalhamento,
              }
            })
            // Monta variável global de relatório PDF
            const valorTotal = [{ valorTotalAcumulo: this.valorTotalAcumulado }]
            this.parametroPDF = {
              dados: itemsPDF,
              colunas: colunasPDF,
              colunas2: colunasPDF2,
              institutoNome: this.userData.Instituto.nome,
              dadosTotal: valorTotal[0].valorTotalAcumulo,
            }
          })
          .catch((error) => {
            console.error(error)
            this.isBusy = false
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      carregaOrgaos() {
        this.isBusy = true
        useJwt
          .pesquisar('./cadastro/orgao/GetPesquisar', {
            institutoSelecionado: this.userData.institutoSelecionado,
          })
          .then((response) => {
            this.isBusy = false
            this.tipoOrgao = this.tipoOrgao.concat(response.data)
            this.totalRows = this.tipoOrgao.length

            if (this.tipoOrgao.length < 2) {
              this.$message.error('Não foram encontrados órgãos!')
            }
          })
          .catch((error) => {
            console.error(error)
          })
      },
      exportToPDF() {
        if (!this.itemsTabela.length > 0) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }
        if (this.$refs.tabelaGrid) {
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2PdfLandscape.$el.innerHTML
          let relatorioPaisagem = true
          let nomeInstituto = this.userData.Instituto.nome
          let periodoFiltro = `Período: ${this.dataInicial} a ${this.dataFinal}`
          let nomeRelatorio = this.nomeRelatorio
          let informacoesInstituto = `
                      ${this.userData.Instituto.logradouro},
                      ${this.userData.Instituto.numero},
                      ${this.userData.Instituto.bairro},
                      ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                      E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                    `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            periodoFiltro,
            nomeRelatorio,
            informacoesInstituto,
            null,
            this.userData.Instituto.id,
          )
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
      exportToExcel() {
        if (!this.itemsTabela.length) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }

        // Usando os dados e colunas para criar a planilha
        const ws = XLSX.utils.json_to_sheet(
          this.itemsTabela.map((item) => ({
            Órgão: item.orgao,
            'Emprestimos Concedidos': item.emprestimosConcedidos,
            'Valor Pago': item.valorPago,
          })),
        )

        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Relatorio')

        // Salvando o arquivo
        XLSX.writeFile(wb, 'Relatorio.xlsx')
      },
    },
    watch: {},
  }
</script>
<style>
  .background-details-table {
    background-color: #f3f2f7;
    color: black;
  }
  body.dark-layout .background-details-table {
    background-color: #343d55;
    color: white;
  }
</style>
