import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import applyVerticalAlignment from '@/views/pages/relatorio/UtilitarioRelatorios'

export default {
  mounted() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs
    this.loadImage()
  },
  methods: {
    gerarRelatorioTaxaJuros(parametros) {
      const linhasTabelaRelatorio = parametros.linhasTabelaRelatorio
      const colunasTabelaRelatorio = parametros.colunasTabelaRelatorio
      const nomeRelatorio = parametros.nomeRelatorio

      const tableContent = []
      const totaisLista = []
      const widths = []
      const fieldsPDF = colunasTabelaRelatorio
      const header = fieldsPDF.map((field) => ({ text: field.label, style: 'tableHeader' }))

      tableContent.push(header)

      linhasTabelaRelatorio.forEach((item, indiceLinha) => {
        const negrito = item.negrito
        indiceLinha == 0 ? widths.push(200) : widths.push(150)
        const row = fieldsPDF.map((field, indiceColuna) => {
          const value = item[field.key]
          const styleLinha = ['tableCell']

          if (indiceColuna == 0) styleLinha.push('conteudoTexto')

          return { text: value, style: styleLinha, bold: negrito }
        })

        tableContent.push(row)
      })

      const documentDefinition = {
        pageSize: 'A4',
        pageOrientation: 'landscape',

        content: [
          {
            text: nomeRelatorio.nome,
            fontSize: 16,
            style: 'title',
            margin: 15,
          },
          {
            text: `PERÍODO: ${parametros.dataInicial} até ${parametros.dataFinal}`,
            fontSize: 10,
            margin: 15,
            alignment: 'center',
          },
          {
            table: {
              headerRows: 1,
              body: tableContent,
            },
            layout: {
              paddingTop: function (index, node) {
                applyVerticalAlignment(node, index, 'center')
                return 0
              },
            },
          },
          {
            text: `Total de parcelas: ${parametros.itemsRelatorio.totalParcelas}`,
          },
          {
            text: `Total de Contratos: ${parametros.itemsRelatorio.totalContratos}`,
          },
          {
            text: `Total de Segurados: ${parametros.itemsRelatorio.totalSegurados}`,
          },
        ],
        footer: {
          columns: [
            {
              text: `${parametros.instituto.logradouro} ${parametros.instituto.numero}, ${parametros.instituto.bairro}, ${parametros.instituto.cep} - ${parametros.instituto.cidade} - ${parametros.instituto.uf}`,
              fontSize: 8,
              margin: 10,
            },
            {
              text: `E-mail: ${parametros.instituto.emailResponsavel} - Fone: ${parametros.instituto.telefone}`,
              fontSize: 8,
              margin: 10,
            },
          ],
        },
        styles: {
          table: {
            alignment: 'center',
          },
          conteudoTexto: {
            alignment: 'left',
            margin: [5, 0, 0, 0],
          },
          tableHeader: {
            bold: true,
            fontSize: 11,
            fillColor: '#f3f3f3',
            alignment: 'center',
            margin: [5, 0, 5, 0],
          },
          tableCell: {
            alignment: 'center',
            fontSize: 11,
            margin: [5, 0, 5, 0],
          },
          title: {
            bold: true,
            fontSize: 16,
            alignment: 'center',
          },
          subtitle: {
            fontSize: 11,
            alignment: 'center',
            margin: [15, 10],
          },
        },
      }

      pdfMake.createPdf(documentDefinition).open()
    },
  },
}
