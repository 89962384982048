<template>
  <div>
    <RelatorioRetrato>
      <b-table
        striped
        small
        class="ExportarPDFParcela mt-3 custom-header-style"
        style="text-align: start"
        id="ExportarPDFParcelaId"
        :head-variant="'light'"
        :items="parametro.dados"
        :fields="parametro.colunas"
        foot-clone
      >
        <template #foot(nomeCompleto)="">
          <span class="text-bold">Valor total:</span>
        </template>
        <template #foot(matricula)=""> <span></span></template>
        <template #foot(codigo)=""> <span></span></template>
        <template #foot(taxaEmprestimoMensal)=""> <span></span></template>
        <template #foot(valor)="">
          <span>{{ parametro.totalizadores.totalValor }}</span>
        </template>
        <template #foot(fundoGarantidor)="">
          <span> {{ parametro.totalizadores.totalFundoGarantidor }}</span>
        </template>
      </b-table>
    </RelatorioRetrato>
  </div>
</template>

<script>
  import pdfMake from 'pdfmake/build/pdfmake'
  import pdfFonts from 'pdfmake/build/vfs_fonts'
  import RelatorioRetrato from '@/views/pages/relatorio/relatorioRetrato.vue'
  import { formatarData, formatarParaNumber, formatarValor, formatarValorPorcentagem } from '@/libs/utils'

  export default {
    components: {
      RelatorioRetrato,
      pdfMake,
      pdfFonts,
      formatarValor,
      formatarData,
    },
    props: {
      parametro: {},
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarValor,
        formatarData,
      }
    },
  }
</script>

<style>
  .fundoRelatorio {
    background-color: white;
    word-break: break-word;
  }

  .titulo {
    background-color: inherit !important;
    justify-content: flex-start;
    word-break: break-all;
    padding-top: 5px;
  }

  .ExportarPDFParcela .thead-light th {
    background-color: #f3f2f700 !important;
    border-color: #4941a6 !important;
    border-block-start: initial !important;
    color: #4941a6 !important;
    font-size: 10px !important;
    padding: 10.5px 0 !important;
    inline-size: max-content !important;
  }

  .lineBlue {
    width: 288px;
    height: 13px;
    background-color: #4941a6;
  }
  .lineGray {
    width: 563px;
    height: 13px;
    background-color: #b8c2cb;
  }
  .instituto {
    font-size: initial;
    padding-left: 20px;
  }
  .tituloRelatorio {
    text-align: center;
    font-weight: bold;
    font-size: large;
    color: #615771;
  }
  .cabecalhoTable {
    margin-bottom: 0px;
    font-size: 9px;
    word-wrap: normal;
    white-space: pre-wrap;
  }

  .periodoRelatorio {
    font-weight: bold;
    color: black;
    text-align: center;
  }

  .totalParcelas {
    font-weight: bold;
    color: black;
    font-size: 9px;
    padding-bottom: 2px;
    padding-left: 20px;
  }

  .rodapeRelatorio {
    font-size: 11px;
    margin-bottom: 0px;
    color: #736c81;
    margin-top: 5px;
    padding-left: 20px;
  }

  .rodapeRelatorioContainer {
    bottom: 0rem;
  }

  .content-wrapper {
    position: relative;
  }

  .bold-footer-row {
    font-weight: bold;
  }

  #ExportarPDFParcelaId thead tr th {
    width: 100px;
  }

  #ExportarPDFParcelaId tbody tr td {
    padding: 3px !important;
  }

  .UltimaTable tbody tr:last-child {
    font-weight: bold;
  }

  #logoAgenda {
    margin: 0 10px;
  }

  div.vue-html2pdf div.card-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .ExportarPDFParcela {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  .margemTotalizador {
    margin-top: 30px;
  }

  .ExportarPDFParcela tbody tr td {
    text-wrap: nowrap;
  }

  .mt-07 {
    margin-top: 0.7rem !important;
  }

  .mt-05 {
    margin-top: 0.5rem !important;
  }

  #radiosTipoDePesquisa > label {
    width: 0.5em;
  }

  #ExportarPDFParcelaId tbody > tr > td {
    font-size: 0.55rem !important;
  }
</style>
