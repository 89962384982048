import { set, differenceInDays, isValid, parseISO } from 'date-fns'

/**
 * Calcula o próximo mês e ano com base na data de referência.
 *
 * @param data - A data de referência.
 * @returns Um objeto contendo o próximo mês e ano.
 * @throws {TypeError} Se o parâmetro for do tipo diferente de Date, string ou number.
 * @throws {RangeError} Se a data de referência não for válida.
 */
export function getNextMonthAndYear(data: Date | string): { nextMonth: number; nextYear: number } {
  let date
  if (typeof data === 'string') {
    date = new Date(data.toString())
  } else {
    date = data as Date
  }

  if (isNaN(date.getTime())) {
    throw new TypeError('A data de referência é inválida')
  } else if (!isValid(date)) {
    throw new RangeError('A data de referência é inválida')
  }

  const currentMonth = date.getMonth() + 1
  const currentYear = date.getFullYear()

  const nextMonth = (currentMonth % 12) + 1
  const nextYear = currentMonth === 12 ? currentYear + 1 : currentYear

  return { nextMonth, nextYear }
}

/**
 * Calcula o número de dias entre duas datas.
 *
 * @param dataInicio - A data de início para o cálculo.
 * @param dataFim - A data de fim para o cálculo.
 * @returns A diferença em dias entre as duas datas ou -1 se alguma data for inválida.
 * @throws {TypeError} Se algum parâmetro for do tipo diferente de Date, string ou number.
 */
export function calcularDiasEntreDatas(dataInicio: Date | string | number, dataFim: Date | string | number): number {
  let startDate: Date | undefined
  let endDate: Date | undefined

  if (typeof dataInicio === 'string') {
    startDate = new Date(dataInicio)
  } else if (typeof dataInicio === 'number') {
    startDate = new Date(dataInicio)
  } else if (dataInicio instanceof Date) {
    startDate = dataInicio
  }

  if (typeof dataFim === 'string') {
    endDate = new Date(dataFim)
  } else if (typeof dataFim === 'number') {
    endDate = new Date(dataFim)
  } else if (dataFim instanceof Date) {
    endDate = dataFim
  }

  if (!startDate || !endDate || isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    throw new TypeError('As datas de início e fim devem ser válidas')
  }

  if (!isValid(startDate) || !isValid(endDate)) {
    throw new RangeError('As datas de início e fim devem ser válidas')
  }

  const diferencas: number = differenceInDays(endDate, startDate)

  return diferencas
}
