import * as XLSX from 'xlsx'
import { formatarData, formatarValorReais } from '@/libs/utils'

const createSheet = (tableData: any[]) => {
  // cria um objeto de planilha vazio
  const workbook = XLSX.utils.book_new()
  // cria uma planilha vazia e adiciona ao objeto de planilha
  const worksheet = XLSX.utils.aoa_to_sheet([[]])
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Geral')

  // adiciona dados da tabela à planilha
  XLSX.utils.sheet_add_json(worksheet, tableData, { origin: -1 })

  // salvar o arquivo XLSX
  XLSX.writeFile(workbook, 'relatorio-de-grpe.xlsx')
}

export const asDefault = () => {
  return {
    convertToExcelFormat: (item, formatarData, formatarValorReais) => {
      return {
        COMPETÊNCIA: item.dataCompetenciaConvertida ?? '',
        'QUANTIDADE DE CONTRATOS': item.quantidadeContratos.toString().replace(/\[|\]|"/g, '') ?? '',
        'N° DO CONTRATO': item.codigo.toString().replace(/\[|\]|"/g, '') ?? '',
        'DATA DE VENCIMENTO': item.dataVencimento ? formatarData(item.dataVencimento) : '',
        'DIA DE REPASSE': item.diaDeRepasse ?? '',
        ÓRGÃO: item.nomeOrgao ?? '',
        'STATUS ARRECADAÇÃO': item.status ?? '',
      }
    },
    export: (items) => {
      const situationItems = items.map((item) => asDefault().convertToExcelFormat(item, formatarData, formatarValorReais))
      createSheet(situationItems)
    },
  }
}
