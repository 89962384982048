<template>
  <div>
    <b-tabs pills>
      <b-tab title="Lançamentos" v-if="acesso.RelatorioFinanceiroLancamento">
        <relatorioFinanceiroLancamento />
      </b-tab>

      <b-tab title="Arrecadação" v-if="acesso.RelatorioFinanceiroArrecadacao">
        <relatorioFinanceiroGRPE />
      </b-tab>

      <b-tab title="Taxas e Parcelas" v-if="acesso.RelatorioFinanceiroTaxasParcelas">
        <RelatorioTaxasParcelas />
      </b-tab>

      <b-tab title="IOF" v-if="acesso.RelatorioFinanceiroIOF">
        <relatorioFinaceiroIOF />
      </b-tab>

      <b-tab title="Capital Aplicado x Rentabilidade" v-if="acesso.RelatorioFinanceiroCapitalAplicado">
        <relatorioFinanceiroCapitalAplicadoXRentabilidade />
      </b-tab>

      <b-tab title="Contábil" v-if="acesso.RelatorioFinanceiroContabil">
        <relatorioFinanceiroContabil />
      </b-tab>

      <b-tab title="Parcelas Antecipadas" v-if="acesso.RelatorioFinanceiroParcelasAntecipadas">
        <relatorioFinanceiroParcelasAntecipadas />
      </b-tab>

      <b-tab title="DAIR" v-if="acesso.RelatorioFinanceiroDAIR">
        <relatorioDAIR />
      </b-tab>
    </b-tabs>
    <b-card class="text-center" v-if="todosFalsos">
      <b-card-body>
        <b-icon icon="exclamation-triangle" variant="danger" font-scale="2"></b-icon>
        <b-card-title class="text-danger mt-2">Acesso Negado!</b-card-title>
        <b-card-text class="mb-1">Parece que seu usuário não tem permissão para acessar abas deste módulo.</b-card-text>
        <b-card-text class="mb-1"
          >Para obter acesso, por favor, solicite assistência ou permissões adicionais ao administrador do sistema.</b-card-text
        >
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import relatorioFinanceiroLancamento from './relatorioFinanceiroLancamento.vue'
  import relatorioFinanceiroGRPE from './relatorioFinanceiroGRPE.vue'
  import relatorioTaxaJuros from './relatorioTaxaJuros/relatorioTaxaJuros.vue'
  import relatorioFinaceiroIOF from './relatorioFinaceiroIOF.vue'
  import relatorioFinanceiroCapitalAplicadoXRentabilidade from './relatorioFinanceiroCapitalAplicadoXRentabilidade.vue'
  import relatorioFinanceiroContabil from './relatorioFinaceiroContabil.vue'
  import relatorioFinanceiroParcelasAntecipadas from './relatorioFinanceiroParcelasAntecipadas.vue'
  import relatorioDAIR from './relatorioDAIR.vue'
  import RelatorioTaxasParcelas from './RelatorioTaxasParcelas/RelatorioTaxasParcelas.vue'

  export default {
    components: {
      relatorioFinanceiroLancamento,
      relatorioFinanceiroGRPE,
      relatorioTaxaJuros,
      relatorioFinaceiroIOF,
      relatorioFinanceiroCapitalAplicadoXRentabilidade,
      relatorioFinanceiroContabil,
      relatorioFinanceiroParcelasAntecipadas,
      relatorioDAIR,
      RelatorioTaxasParcelas,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        acesso: {
          RelatorioFinanceiroLancamento: false,
          RelatorioFinanceiroArrecadacao: false,
          RelatorioFinanceiroTaxasParcelas: false,
          RelatorioFinanceiroIOF: false,
          RelatorioFinanceiroCapitalAplicado: false,
          RelatorioFinanceiroContabil: false,
          RelatorioFinanceiroParcelasAntecipadas: false,
          RelatorioFinanceiroDAIR: false,
        },
      }
    },
    computed: {
      todosFalsos() {
        return Object.values(this.acesso).every((valor) => valor === false)
      },
    },

    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.RelatorioFinanceiroLancamento = true
        this.acesso.RelatorioFinanceiroArrecadacao = true
        this.acesso.RelatorioFinanceiroTaxasParcelas = true
        this.acesso.RelatorioFinanceiroIOF = true
        this.acesso.RelatorioFinanceiroCapitalAplicado = true
        this.acesso.RelatorioFinanceiroContabil = true
        this.acesso.RelatorioFinanceiroParcelasAntecipadas = true
        this.acesso.RelatorioFinanceiroDAIR = true
      } else {
        this.acesso.RelatorioFinanceiroLancamento = this.userData.GrupoAcesso.RelatorioFinanceiroLancamento
        this.acesso.RelatorioFinanceiroArrecadacao = this.userData.GrupoAcesso.RelatorioFinanceiroArrecadacao
        this.acesso.RelatorioFinanceiroTaxasParcelas = this.userData.GrupoAcesso.RelatorioFinanceiroTaxasParcelas
        this.acesso.RelatorioFinanceiroIOF = this.userData.GrupoAcesso.RelatorioFinanceiroIOF
        this.acesso.RelatorioFinanceiroCapitalAplicado = this.userData.GrupoAcesso.RelatorioFinanceiroCapitalAplicado
        this.acesso.RelatorioFinanceiroContabil = this.userData.GrupoAcesso.RelatorioFinanceiroContabil
        this.acesso.RelatorioFinanceiroParcelasAntecipadas = this.userData.GrupoAcesso.RelatorioFinanceiroParcelasAntecipadas
        this.acesso.RelatorioFinanceiroDAIR = this.userData.GrupoAcesso.RelatorioFinanceiroDAIR
      }
    },
  }
</script>
