<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="ConsigPrev - Relatório"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      @beforeDownload="beforeDownload($event)"
      ref="html2PdfLandscape"
    >
      <section slot="pdf-content">
        <slot></slot>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
  import VueHtml2pdf from 'vue-html2pdf'

  export default {
    components: {
      VueHtml2pdf,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        htmlPdfOptions: {
          margin: [0, 0, 0.35, 0],
          html2canvas: {
            scale: 1,
            useCORS: true,
          },
          jsPDF: {
            unit: 'in',
            format: 'a4',
            orientation: 'landscape',
          },
        },
      }
    },
    methods: {
      hasGenerated() {},
      hasStartedGeneration() {},
      async beforeDownload({ html2pdf, options, pdfContent }) {
        await html2pdf()
          .set(options)
          .from(pdfContent)
          .toPdf()
          .get('pdf')
          .then((pdf) => {
            const totalPages = pdf.internal.getNumberOfPages()

            for (let pagina = 1; pagina <= totalPages; pagina++) {
              pdf.setPage(pagina)
              pdf.setFontSize(10)
              pdf.setTextColor(150)

              pdf.text(
                'Pagina ' + pagina + ' de ' + totalPages,
                pdf.internal.pageSize.getWidth() * 0.88,
                pdf.internal.pageSize.getHeight() - 0.15,
              )

              pdf.setFontSize(7)
              pdf.text(
                `${this.userData.Instituto.logradouro}, ${this.userData.Instituto.numero}, ${this.userData.Instituto.bairro}, ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf} E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}`,
                pdf.internal.pageSize.getWidth() * 0.03,
                pdf.internal.pageSize.getHeight() - 0.15,
              )
            }
          })
          .save()
      },
    },
  }
</script>

<style>
  #ExportarPDFParcelaId thead > tr > th > div {
    font-size: 0.6rem !important;
  }

  #ExportarPDFParcelaId tbody > tr > td {
    font-size: 0.55rem !important;
  }
</style>
